@import "./assets/css/reset.css";
@import "./assets/css/skeleton.css";

@import url("http://fonts.googleapis.com/css?family=Ubuntu+Mono:400,700");
@import url("http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,300italic,600italic");

/**
* Base
*/

html,
body {
  min-height: 100%;
}

body {
  font: 12px/24px Helvetica, Arial, sans-serif;
  color: #ffffff;
  background-color: #323232;
  background-image: url("./assets/img/background5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  // background-position-x: -167px;
}
