/**
 * Mixins
 */

@mixin border-radius($radius: 6px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin ease-in-out($seconds: 0.2s) {
  -webkit-transition: all $seconds ease-in-out;
  -moz-transition: all $seconds ease-in-out;
  -o-transition: all $seconds ease-in-out;
  transition: all $seconds ease-in-out;
}
