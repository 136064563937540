@import "./scss/mixins.scss";

/**
* Header
*/

div.header {
  text-align: center;
  h1 {
    color: rgba(255, 255, 255, 0.8);
    font-family: "Ubuntu Mono", sans-serif;
    font-weight: 700;
    line-height: 1.2em;
    font-size: 5.8em;
    margin: 20px 0;

    @media only screen and (max-width: 767px) {
      font-size: 2em;
    }

    @media only screen and (max-width: 959px) and (min-width: 768px) {
      font-size: 4.8em;
    }

    @media only screen and (max-width: 767px) and (min-width: 480px) {
      font-size: 5.7em;
    }
  }
}

div.video {
  padding-top: 5%;
  text-align: center;

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/**
* Timer
*/

div.timeblock {
  margin-top: 5%;
  width: 100%;
  background: #81cece;
  background: rgba(129, 206, 206, 0.5);

  div.timer {
    color: rgba(255, 255, 255, 0.8);
    font-family: "Ubuntu Mono", sans-serif;
    font-weight: 700;
    font-size: 10em;
    line-height: 1.2em;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 767px) {
      font-size: 4.8em;
    }

    @media only screen and (max-width: 959px) and (min-width: 768px) {
      font-size: 7.7em;
    }

    @media only screen and (max-width: 767px) and (min-width: 480px) {
      font-size: 5.7em;
    }

    ul {
      display: inline-block;

      li {
        float: left;
        text-align: center;
        position: relative;

        &.seperator {
          font-size: 0.7em;
        }

        p {
          font-weight: 200;
          font-size: 0.27em;
          line-height: 1em;
        }
      }
    }
  }
}

/**
* Social buttons
*/

div.social {
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: center;
  border-bottom: 1px solid #ccc;

  div.column {
    border-top: 1px solid #ccc;
    padding: 40px 0;

    @media only screen and (max-width: 767px) {
      border-top: 0px;
      padding: 0px;
    }

    a {
      @include border-radius(100%);
      @include ease-in-out(0.2s);
      display: inline-block;
      color: white;
      font-size: 1.5em;
      text-decoration: none;
      width: 145px;
      height: 145px;
      background-color: rgba(230, 122, 121, 0.8);
      background-repeat: no-repeat;
      background-position: center;
      text-indent: -9999px;
      overflow: hidden;
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);

      @media only screen and (max-width: 767px) {
        @include border-radius(0px);
        width: 100%;
      }

      &:hover {
        background-color: rgba(230, 122, 121, 1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
      }

      &.venmo {
        background-image: url("./assets//img/venmo.svg");
        background-size: 48px;
      }

      &.cashapp {
        background-image: url("./assets//img/cashapp.svg");
        background-size: 48px;
      }

      &.paypal {
        background-image: url("./assets//img/paypal.svg");
        background-size: 48px;
      }
    }

    span {
      display: block;
      margin-top: 10px;
      font: 1.7em "Source Sans Pro";
      font-weight: 300;
      text-transform: uppercase;

      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
